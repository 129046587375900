<template>
  <div class="content">
    <b-container v-bind:class="{'container-lg': isShowExtended}">
      <!-- User Interface controls -->
      <b-form inline v-b-visible="visibleHandler" v-if="!isScreenshotMode">
        <b-form-checkbox 
          v-model="isMobileView"
          switch unchecked-value="false"
          value="lg" size="lg" class="mr-2"
        >
          Vue mobile
        </b-form-checkbox>
        <b-button class="ml-2 d-none d-lg-block" variant="secondary" size="sm" @click="reload">
          <b-icon-arrow-repeat></b-icon-arrow-repeat>
          Forcer une mise à jour
        </b-button>
        <time-elapsed v-if="last_update" :date="last_update" class="d-none d-lg-block">
          Dernière mise à jour:
        </time-elapsed>

        <b-form-checkbox 
          v-model="isShowExtended" switch size="lg" class="d-none d-lg-block mr-2"
        >
          Affichage étendu
        </b-form-checkbox>
        <b-form-checkbox 
          v-model="isShowStaging" :value="true" :unchecked-value="false" switch size="lg" class="d-none d-lg-block mr-2"
        >
          Tous les bassins
        </b-form-checkbox>
        <div class="ml-auto">
          <a size="sm" v-if="token_distributeur" target="_new" :href="'https://automation.ac/app/index.php?api=' + token_distributeur"><b-icon-folder-symlink></b-icon-folder-symlink>Automation.ac (distributeur)</a>
        </div>
      </b-form>
      <h1 v-if="isScreenshotMode">DomoPiscine {{datenow | dateFormat('DD/MM/YYYY HH:mm')}}</h1>

      <b-modal v-model="showSettings" size="huge" scrollable :title="showSettingsItem.name" v-if="showSettingsItem">
        <param-device
          @mustreload="reload"
          :device="showSettingsItem">
        </param-device>
      </b-modal>

      <b-modal
        ref="logsmodal"
        scrollable
        variant="primary"
        centered size="lg"
        header-bg-variant="dark"
        header-text-variant="light"
        footer-bg-variant=""
        ok-variant="success"
        :title="show_logs_title"
      >
        <display-logs
          ref="displaylogs"
          :device_id="show_logs_device_id"
          :show_seen="show_logs_seen"
          @selectionchanged="onLogsSelectionChanged">    
        </display-logs>
        <template v-slot:modal-footer>
          <div class="w-100">
            <div class="float-left">
              <b-button variant="secondary" @click="onLogsSelectAll" class="mr-2">
                Tout sélectionner
              </b-button>
              <b-button variant="warning" @click="onLogsUnSelectAll">
                Tout déselectionner
              </b-button>
            </div>
            <b-button
              :variant="logids_to_remove.length > 0 ? 'success' : 'primary'"
              class="float-right"
              @click="onLogsSave()"
            >
              <div v-if="logids_to_remove.length == 0">
                Fermer
              </div>
              <div v-else>
                Acquitter {{logids_to_remove.length}} modifications
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <b-modal
        ref="bidonlogsmodal"
        scrollable
        variant="primary"
        centered size="lg"
        header-bg-variant="dark"
        header-text-variant="light"
        footer-bg-variant=""
        ok-variant="success"
        :title="show_logs_title"
      >
        <display-bidon-logs
          ref="displaylogs"
          :device_id="show_logs_device_id">
        </display-bidon-logs>
      </b-modal>

      <b-alert :show="fetcherror !== ''" variant="danger">
        {{fetcherror}}
      </b-alert>
      <b-table
        ref="table"
        striped
        sticky-header="600px"
        hover
        show-empty
        class="devicestable"
        :class="{ 'sticky': stickyheader }"
        primary-key="id"
        :busy="isBusy"
        :stacked="isMobileView"
        :fields="fields"
        :items="items"
        :filter="filter"
        :filterIncludedFields="filterOn"
        :tbody-tr-attr="rowAttrs"
        sort-by="name"
        @row-clicked="onRowClicked"
      >
        <!-- Loading -->
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Chargement...</strong>
          </div>
        </template>

        <!-- Cells -->
        <template v-slot:cell(name)="data">
          <b-icon-arrow-up v-if="hasAlarm(data.item)" :id="$id('alarmtooltip-' + data.item.id)"></b-icon-arrow-up>
          <b-popover
            v-if="hasAlarm(data.item)"
            custom-class="wide-popover"
            ref="alarmpopover"
            :target="$id('alarmtooltip-' + data.item.id)"
            triggers="hover"
            placement="topright"
          >
            <alarms-triggered :reasons="getAlarmReasons(data.item)"></alarms-triggered>
          </b-popover>
          <b class="text-info p-0">{{ data.item.name }}</b>
          &nbsp;
          <a size="sm" v-if="data.value" target="_new" :href="'https://automation.ac/app/index.php?api=' + data.item.token"><b-icon-folder-symlink></b-icon-folder-symlink></a>
        </template>

        <!-- <template v-slot:cell(version_last)="data">
          <table-cell-bool
            tooltip="Version"
            :tooltip_text="data.item.version"
            :value="data.value"
            :dangervalue="false"
            v-if="! isSolista(data.item)">
          </table-cell-bool>
        </template> -->

        <!-- <template v-slot:cell(connected)="data">
          <table-cell-bool
            tooltip="Connecté"
            :tooltip_text="data.value.value ? 'Oui' : 'Non'"
            :value="data.value.value"
            :enabled="data.value.enabled"
            :dangervalue="false"
            v-if="! isSolista(data.item)">
            <template v-slot:tooltip_text>
              <div v-if="! data.value.value">
                Date: {{data.item.last_update | dateFormat('DD/MM/YYYY HH:mm')}}
              </div>
              <div v-else>
                Date: {{data.item.last_fetch | dateFormat('DD/MM/YYYY HH:mm')}}
              </div>
            </template>
          </table-cell-bool>
        </template> -->

        <!-- Pumps -->
        <template v-slot:cell(pumps)="data">
          <div style="position: relative;" v-if="!isSolista(data.item)">
            <pumps :device="data.item"></pumps>
          </div>
        </template>
        <!-- End pumps -->

        <!-- Bidons -->
        <template v-slot:cell(bidon_acide)="data">
          <a @click="onDislayBidonLogs(data.item)">
            <table-cell-bool
              :tooltip="data.value.name ? data.value.name : 'Fin de bidon PH-'"
              :value="data.value.value"
              :enabled="data.value.enabled"
              :dangervalue="false"
              v-if="! isSolista(data.item)">
              <template v-slot:tooltip_text>
                <div v-if="data.value.enabled">
                    {{data.value.value ? 'Présent' : 'Absent'}}<br>
                    <span v-if="data.value.bidon_last_changed">
                      Dernier changement: {{data.value.bidon_last_changed}}
                    </span>
                </div>
                <div v-else>
                  Non installé
                </div>
              </template>
            </table-cell-bool>
          </a>
        </template>
        <template v-slot:cell(bidon_traitement)="data">
          <a @click="onDislayBidonLogs(data.item)">
            <table-cell-bool
              :tooltip="data.value.name ? data.value.name : 'Fin de bidon désinfectant'"
              :value="data.value.value"
              :enabled="data.value.enabled"
              :dangervalue="false"
              v-if="! isSolista(data.item)"
              @click="onDislayBidonLogs(data.item)">
              <template v-slot:tooltip_text>
                <div v-if="data.value.enabled">
                    {{data.value.value ? 'Présent' : 'Absent'}}<br>
                    <span v-if="data.value.bidon_last_changed">
                      Dernier changement: {{data.value.bidon_last_changed}}
                    </span>
                </div>
                <div v-else>
                  Non installé
                </div>
              </template>
            </table-cell-bool>
          </a>
        </template>
        <template v-slot:cell(bidon_algicide)="data">
          <a @click="onDislayBidonLogs(data.item)">
            <table-cell-bool
              :tooltip="data.value.name ? data.value.name : 'Fin de bidon algicide'"
              :value="data.value.value"
              :enabled="data.value.enabled"
              :dangervalue="false"
              v-if="! isSolista(data.item)"
              @click="onDislayBidonLogs(data.item)">
              <template v-slot:tooltip_text>
                <div v-if="data.value.enabled">
                    {{data.value.value ? 'Présent' : 'Absent'}}<br>
                    <span v-if="data.value.bidon_last_changed">
                      Dernier changement: {{data.value.bidon_last_changed}}
                    </span>
                </div>
                <div v-else>
                  Non installé
                </div>
              </template>
            </table-cell-bool>
          </a>
        </template>
        <template v-slot:cell(debit)="data">
          <table-cell-bool
            tooltip="Débit"
            :tooltip_text="getDebitLabel(data)"
            :value="data.value.value"
            :enabled="data.value.enabled"
            :dangervalue="false"
            :successclass="getDebitSuccessClass(data)"
            :dangerclass="getDebitDangerClass(data)"
            v-if="! isSolista(data.item)">
          </table-cell-bool>
        </template>

        <template v-slot:cell(pac)="data">
          <table-cell-equipment
            v-if="data.value && ! isSolista(data.item)"
            tooltip="PAC"
            :enabled="data.value.raw.isOff == '1'"
            :value="data.value.raw.isOff"
            truevalue="1"
            trueclass="bg-yellow">
            <template v-slot:tooltip_text>
              <p>Mode: {{data.value.mode}}<br/>
              Programmation: {{data.value.programmation}}<br/>
              Consigne: {{data.value.consigne}} {{data.value.unit_consigne}}
            </p>
            </template>
          </table-cell-equipment>
        </template>

        <template v-slot:cell(logs_to_see)="data">
          <b-button
            size="sm"
            variant="transparent"
            @click="onDisplayLogs(data.item, !data.value)"
            v-if="!isSolista(data.item)"
          >
            <b-icon icon="exclamation-triangle-fill" scale="2" :variant="data.value ? 'warning' : 'success'"
            ></b-icon>
          </b-button>
        </template>
        <template v-slot:cell(pressure_service)="data">
          {{getRowAlarmNamed("pressure", data.item, 'single').minimum}}
        </template>
        <template v-slot:cell(last_fetch)="data">
          <b-icon-alarm
            variant="warning"
            v-if="isDataTooOld(data.item)"
            v-b-tooltip.hover title="Données trop anciennes (> 2h)"
            >
          </b-icon-alarm>
        </template>
      <!--   <template v-slot:cell(pressure)="data">
          <transition name="blink" mode="out-in">
            <table-cell-string
              v-if="data.value.value !== 'undefined' && data.value.value !== null && parseFloat(data.value.value) >= 0.1"
              tooltip="Pression"
              :rowalarm="getRowAlarm(data.item, data.value)"
              :key="data.value.value"
            >
              <template v-slot:tooltip_text>
                {{data.value.value}} {{data.value.unit}}
              </template>
              {{data.value.value}}
            </table-cell-string>
            <table-cell-string
              v-else
              tooltip="Pression"
              :rowalarm="getRowAlarm(data.item, data.value)"
              :key="data.value.value"
            >
              <template v-slot:tooltip_text>
                <div v-if="data.value.enabled && data.value.value !== null">
                  Valeur trop faible: {{data.value.value}} &lt; 0.1
                </div>
                <div v-else>
                  Non installé
                </div>
              </template>
              -
            </table-cell-string>
          </transition>
        </template> -->
        <template v-slot:cell(pressure)="data">
          <transition name="blink" mode="out-in">
            <table-cell-string
              v-if="data.value.value !== 'undefined' && data.value.value !== null"
              tooltip="Pression"
              :rowalarm="getRowAlarm(data.item, data.value)"
              :key="data.value.value"
            >
              <template v-slot:tooltip_text>
                {{data.value.value}} {{data.value.unit}}
              </template>
              {{ getPressureText(data.value.value) }}
            </table-cell-string>
            <table-cell-string
              v-else
              tooltip="Pression"
              :rowalarm="getRowAlarm(data.item, data.value)"
              :key="data.value.value"
            >
              <template v-slot:tooltip_text>
                <div v-if="! data.value.enabled">
                  Non installé
                </div>
              </template>
            </table-cell-string>
          </transition>
        </template>


        <!-- Default cell -->
        <template v-slot:cell()="data">
          <transition name="blink" mode="out-in">
            <table-cell-string
              v-if="data.value.value !== 'undefined'"
              :tooltip="data.value.name"
              :rowalarm="getRowAlarm(data.item, data.value)"
              :key="data.value.value"
            >
              <template v-slot:tooltip_text>
                {{data.value.value}} ({{data.value.unit}})
              </template>
              {{data.value.value}}
            </table-cell-string>
            <table-cell-string v-else :key="data.value.value">{{data.value}}</table-cell-string>
          </transition>
        </template>
        <!-- table header cell -->
        <template v-slot:head()="data">
          <div><span>{{data.label}}</span></div>
        </template>
      </b-table>
    </b-container>
  </div>
</template>
<script>
import * as moment from 'moment'
import { API_LOCATION } from '../config.js'
import AlarmsTriggered from '../components/AlarmsTriggered'
import DisplayBidonLogs from '../components/DisplayBidonLogs'
import DisplayLogs from '../components/DisplayLogs'
import ParamDevice from '../components/ParamDevice'
import Pumps from '../components/Pumps'
import TableCellBool from '../components/TableCellBool'
import TableCellEquipment from '../components/TableCellEquipment'
import TableCellString from '../components/TableCellString'
import TimeElapsed from '../components/TimeElapsed'

export default {
  data() {
    return {
      isMobileView: "lg",
      isShowExtended: false,
      isShowStaging: false,
      filter: null,
      small: false,
      filterOn: ["name"],
      stickyheader: false,
      isBusy: false,
      busyTimer: null,
      show_logs_device_id: null,
      show_logs_title: '',
      show_logs_seen: false,
      fetcherror: '',
      last_update: null,
      logids_to_remove: [],
      token_distributeur: '',
      items: [],
      all_items: [],
      showSettings: false,
      showSettingsItem: null,
      socket: null,
      timeouts: {},
    }
  },
  computed: {
    fields: function() {
      return [
        {
          key: 'name',
          label: 'Nom',
          stickyColumn: true,
          thStyle: 'min-width: 16em;',
          sortable: true,
          formatter: (value, key, item) => {
            return (
              (this.isProduction(item) ? '0' : '1') +
              '_' + (this.hasAlarm(item) ? '0' : '1') +
              '_' + item.name.trim())
          },
          sortByFormatted: true,
        }, {
          key: 'logs_to_see',
          label: ''
        }, {
          key: 'debit',
          label: 'Débit'
        }, {
          key: 'pressure',
          label: 'Pression',
          unit: 'Bar'
        }, {
          key: 'pumps',
          label: 'Pompes',
          thStyle: 'min-width: 7em;'
        }, {
          key: 'ph',
          label: 'pH',
        }, {
          key: 'redox',
          label: 'Redox',
          unit: 'mV'
        }, {
          key: 'temperature',
          label: 'Temp.',
          unit: '°C'
        }, {
          key: 'pac',
          label: 'PAC',
        }, {
          key: 'sel',
          label: 'Sel',
          unit: 'mV',
          thClass: this.isShowExtended ? '' : 'd-none',
          tdClass: this.isShowExtended ? '' : 'd-none'
        }, {
          key: 'bidon_acide',
          label: 'Bidon pH'
        }, {
          key: 'bidon_traitement',
          label: 'Bidon dés.'
        }, {
          key: 'bidon_algicide',
          label: 'Bidon alg.'
        }, {
          key: 'last_fetch',
          label: ''
        }
      ]
    },
    isScreenshotMode: function() {
      return (localStorage && localStorage.screenshotmode === 'true')
    },
    datenow: function() {
      return new Date()
    }
  },
  filters: {
    dateFormat(value, format) {
      return moment(value).format(format)
    },
  },
  methods: {
    getPressureText(value) {
      let fval = parseFloat(value)
      if (fval < 70) {
        return ""
      } else if (fval >= 70 && fval < 130 ) {
        return "OK"
      } else if (fval >= 130) {
        return "Colmaté"
      }
    },
    getDebitLabel(data) {
      if (data.value.enabled) {
        if (data.value.value) {
          for (let e of data.item.equipments) {
            if (e.name.toLowerCase() === "filtration" && e.mode === "OFF") {
              return 'Bloqué à 1'
            }
          }
          return 'Présent'
        } else {
          if (parseFloat(data.item.pressure.value) >= 70) {
            return 'Bloqué à 0'
          } else {
            return 'Absent'
          }
        }
      } else {
        return 'Non installé'
      }
    },
    getDebitDangerClass(data) {
      let label = this.getDebitLabel(data)
      if (label == 'Bloqué à 0' || label == 'Bloqué à 1') {
        return 'bg-warning'
      }
      return 'hidden'
    },
    getDebitSuccessClass(data) {
      let label = this.getDebitLabel(data)
      if (label == 'Bloqué à 0' || label == 'Bloqué à 1') {
        return 'bg-warning'
      }
      return 'bg-info'
    },
    filterItems() {
      return this.$data.all_items.filter((d => d.production !== this.isShowStaging || d.production !== this.isScreenshotMode))
    },
    isDataTooOld(item) {
      if (item["last_fetch"]) {
        return moment(item["last_fetch"]).add(2, 'h').isBefore(moment.utc())
      } else {
        return false
      }
    },
    onDisplayLogs(item, show_logs_seen) {
      this.logids_to_remove = []
      this.show_logs_device_id = item.id
      this.show_logs_title = 'Modifications ' + item.name
      this.show_logs_seen = show_logs_seen
      this.$refs['logsmodal'].show()
    },
    onLogsSelectAll() {
      this.$refs['displaylogs'].selectAll()
    },
    onLogsUnSelectAll() {
      this.$refs['displaylogs'].unselectAll()
    },
    onLogsSelectionChanged(log_ids) {
      this.logids_to_remove = log_ids
    },
    onLogsSave() {
      let logs_ids = this.logids_to_remove
      if (this.logids_to_remove.length == 0) {
        return this.$refs.logsmodal.hide()
      }
      let device_id = this.show_logs_device_id
      this.logids_to_remove = []
      if (logs_ids && device_id) {
        this.resetLogsNotSeen(device_id, logs_ids)
      }
      this.logids_to_remove = []
    },
    resetLogsNotSeen(device_id, logs_ids=[]) {
      this.$http.post(API_LOCATION + '/device/' + device_id + '/resetnotseen',
        {ids: logs_ids}
      ).then((response) => {
        this.$refs.displaylogs.removeLogIds(logs_ids)
        if (response.body)
        {
          for(const [i, item] of this.all_items.entries()) {
            if (item.id == device_id) {
              return this.$set(
                this.all_items[i], 'logs_to_see', response.body.logs_to_see)
            }
          }
        }
      })
    },
    isSolista(item) {
      return item.solista
    },
    isProduction(item) {
      return item.production
    },
    getAlarmReasons(item) {
      let reasons = []
      for(let sensor_name of ['pressure', 'ph', 'redox', 'sel', 'temperature']) {
        let rowalarm = this.getRowAlarmNamed(sensor_name, item)
        if (rowalarm && rowalarm.triggered) {
          reasons.push({type: 'alarm', alarm: rowalarm, sensor: sensor_name})
        }
      }
      for(let e of item.equipments) {
        if (e.name.toLowerCase() == "filtration") {
          let requiredmode = 'AUTO'
          if (item.variable_pressure) {
            requiredmode = 'ON'
          }
          if (Boolean(e.mode) && e.mode !== requiredmode) {
            reasons.push({type: 'pump', mode: e.mode})
          }
        }
      }
      if (item.logs_to_see && !this.isSolista(item)) {
        reasons.push({'type': 'logs'})
      }
      if (item.bidon_traitement.enabled && ! item.bidon_traitement.value) {
        reasons.push({type: 'bidon', bidon: item.bidon_traitement})
      }
      if (item.bidon_acide.enabled && ! item.bidon_acide.value) {
        reasons.push({type: 'bidon', bidon: item.bidon_acide})
      }
      if (item.bidon_algicide.enabled && ! item.bidon_algicide.value) {
        reasons.push({type: 'bidon', bidon: item.bidon_algicide})
      }
      return reasons
    },
    hasAlarm(item) {
      return this.getAlarmReasons(item).length > 0
    },
    rowAttrs(item, type) {
      var style = ""
      if (!item || type !== 'row') {
        return {}
      }
      for(let sensor_name of ['pressure', 'ph', 'redox', 'sel']) {
        let rowalarm = this.getRowAlarmNamed(sensor_name, item)
        if (
          rowalarm &&
          rowalarm.triggered &&
          rowalarm.param_alarm &&
          rowalarm.param_alarm.colorrow === true &&
          rowalarm.param_alarm.bgcolor)
        {
          console.log("rowalarm triggered", rowalarm)
           style = "background-color: " + rowalarm.param_alarm.bgcolor + ";"
           break
        }
      }
      if (!this.isSolista(item) && item.connected.enabled) {
        style += "border-left: 2px solid " + (item.connected.value ? "var(--green)" : "var(--red)")
      }

      if (style) {
        return {
          "style": style
        }
      } else {
        return {}
      }
    },
    getRowAlarmNamed: function(sensor_name, item, variant=null, filter_triggered=true) {
      let sensor = item[sensor_name]
      return this.getRowAlarm(item, sensor, variant, filter_triggered)
    },
    getRowAlarm: function(item, sensor, variant=null, filter_triggered=true) {
      /* Si il n'y a pas de capteur de debit, on active les alarmes.
       * Si un capteur est présent, il faut que la pompe soit active
       * 
       * Si le débit est présent, mais que le débit n'est pas actif,
       * on teste aussi la pression, si on a >= 70 %, on active la gestion
       * des alarmes. (Compenser une sonde débit faussée)
       */

      /* Les pompes a vitesse varibles ne doivent pas déclencher les alarmes
         de pression
      */
      if (item.variable_pressure && (sensor.techname == 'pressure' || sensor.techname == 'pressure_service')) {
        return {
          label: null,
          triggered: false,
          triggered_reasons: [],
          param_alarm: null,
        }
      }

      if (
        (item.debit.enabled === true && item.debit.value === true) ||
        (item.debit.enabled === true && item.debit.value === false && item.pressure.value && parseFloat(item.pressure.value) >= 70 ) || 
        item.debit.enabled === false || variant !== null
      ) {
        if (sensor.param_alarm !== 'undefined' && sensor.param_alarm) {
          let matches = []
          for(let pa of sensor.param_alarm) {
            let period_match = true
            let triggered = false
            let triggered_reasons = []
            let range_absdiff = Number.MAX_SAFE_INTEGER
            if (pa.period_start != null && pa.period_end != null) {
              period_match = moment().isBetween(
                moment(pa.period_start, 'DD/MM'),
                moment(pa.period_end, 'DD/MM'))
              range_absdiff = Math.abs(
                moment(pa.period_start, 'DD/MM') - moment(pa.period_end, 'DD/MM'))
            } else if (pa.period_start !== null && pa.period_end === null) {
              period_match = moment().isAfter(moment(pa.period_start, 'DD/MM'))
            } else if (pa.period_start === null && pa.period_end !== null) {
              period_match = moment().isBefore(moment(pa.period_end, 'DD/MM'))
            } else {
              period_match = true // Of course
            }
            if (! period_match) {
              continue
            }
            if (pa.variant !== variant) {
              continue
            }
            if (filter_triggered) {
              if (pa.minimum !== null && sensor.value <= pa.minimum) {
                triggered = true
                triggered_reasons.push(sensor.value + ' est sous le minimum (' + pa.minimum + ')')
              }
              if (pa.maximum !== null && sensor.value >= pa.maximum) {
                triggered = true
                triggered_reasons.push(sensor.value + ' est au dessus du maximum (' + pa.maximum + ')')
              }
            }
            matches.push({
              range_absdiff: range_absdiff,
              minimum: pa.minimum,
              maximum: pa.maximum,
              label: pa.minimum + " - " + pa.maximum,
              triggered: triggered,
              triggered_reasons: triggered_reasons,
              param_alarm: pa,
            })
          }
          /* Now we need to select best match */
          if ( matches.length > 0 ) {
            matches.sort((a, b) => {return a.range_absdiff > b.range_absdiff ? 1 : -1})
            return matches[0]
          }
        }
      }


      if ((sensor.techname == 'temperature' || sensor.techname == 'redox') ) {
        if (parseFloat(sensor.value) == 0) {
          return {
            range_absdiff: Number.MAX_SAFE_INTEGER,
            minimum: 0,
            maximum: 0,
            label: "0",
            triggered: true,
            triggered_reasons: [sensor.name + ' est à zéro'],
            param_alarm: null,
          }
        }
      }

      return {
        label: null,
        triggered: false,
        triggered_reasons: [],
        param_alarm: null,
      }
    },
    onRowClicked(item) {
      this.showSettingsItem = item
      this.showSettings = true
    },
    onDislayBidonLogs(item) {
      this.logids_to_remove = []
      this.show_logs_device_id = item.id
      this.show_logs_title = 'Historique changement de bidon ' + item.name
      this.$refs['bidonlogsmodal'].show()

    },
    visibleHandler(isVisible) {
      this.stickyheader = !isVisible
    },
    reload: function() {
      if (this.$data.busyTimer != null) {
        clearTimeout(this.$data.busyTimer)
      }
      this.$data.busyTimer = setTimeout(() => {this.$data.isBusy = true}, this.$data.all_items.length > 0 ? 15000 : 1000)
      this.fetcherror = ''
      this.$http.get(API_LOCATION + '/devices').then(response => {
        if (this.$data.busyTimer != null) {
          clearTimeout(this.$data.busyTimer)
        }
        this.last_update = moment.utc()
        this.$data.isBusy = false
        this.token_distributeur = response.body.token_distributeur
        let devices = response.body.devices
        let table = this.$refs.table
        // Don't close opened editor
        if (table && table.all_items) {
          for(let device of devices) {
            for(let tableitem of this.$refs.table.all_items) {
              if (tableitem.id == device.id && tableitem._showDetails === true) {
                device._showDetails = true
              }
            }
          }
        }

        for(let d of devices) {
          for (let e of d.equipments) {
            if (e.name.toLowerCase() == 'chauffage') {
              d.pac = e
            }
          }
        }
        this.$data.all_items = devices
        this.$data.items = this.filterItems()
      }, response => {
        if (response.status === 403 || response.status === 401) {
          this.$router.push('/logout')
          return
        }
        this.fetcherror = response.body
        if (this.$data.busyTimer != null) {
          clearTimeout(this.$data.busyTimer)
        }
      })
    },
    reload_device: function(device_id) {
      this.$http.get(API_LOCATION + '/device/' + device_id).then(response => {
        delete this.timeouts[device_id]
        let device = response.body.device
        if (! device) {
          return
        }
        let table = this.$refs.table

        for (let e of device.equipments) {
          if (e.name.toLowerCase() == 'Chauffage') {
            device.pac = e
          }
        }

        // Don't close opened editor
        if (table && table.all_items) {
          for(let [index, tableitem] of this.$refs.table.all_items.entries()) {
            if (tableitem.id == device.id) {
              device._showDetails = tableitem._showDetails
              this.$set(this.$data.all_items, index, device)
            }
          }
        }
      }, response => {
        delete this.timeouts[device_id]
        console.error("reload_device error", response)
      })
    }
/*    websocketReconnect: function() {
      this.socket = new WebSocket(((window.location.protocol === "https:") ? "wss://" : "ws://") +
        window.location.hostname + "/ws")
        // window.location.hostname + ":9090/ws")
      this.socket.onmessage = (evt) => {
        let msg = JSON.parse(evt.data)
        let timeout = this.timeouts[msg.device_id]
        if (timeout !== undefined) {
          return
        } else {
          this.timeouts[msg.device_id] = setTimeout(() => this.reload_device(msg.device_id), 1000)
        }
      }
      this.socket.onerror = (evt) => {
        console.log("socket error: ", evt)
      }
      this.socket.onclose = () => {
        this.socket = null
        setTimeout(() => this.websocketReconnect(), 5000)
      }
    } */
  },
  watch: {
    isShowExtended: function(val) {
      if (localStorage) {
        localStorage.setItem("isShowExtended", val)
      }
    },
    isShowStaging: function(new_val, old_val) {
      if (old_val !== new_val) {
        if (localStorage) {
          localStorage.setItem("isShowStaging", new_val)
        }
      }
      this.$data.items = this.filterItems()
    },
    isMobileView: function(val) {
      if (localStorage) {
        localStorage.setItem("isMobileView", val)
      }
    }
  },
  mounted () {
    this.$root.$on('global_filter', (filter) => {
      this.$data.filter = filter
    })
    this.reload()
    setInterval(()=>{this.reload()}, 60000)
    if (localStorage) {
      if (localStorage.isShowExtended) {
        this.isShowExtended = JSON.parse(localStorage.isShowExtended)
      }
      if (localStorage.isMobileView) {
        this.isMobileView = localStorage.isMobileView
      }
      if (localStorage.isShowStaging) {
        this.isShowStaging = JSON.parse(localStorage.isShowStaging)
      }
    }
    // this.websocketReconnect()
  },
  components: {
    AlarmsTriggered,
    DisplayLogs,
    DisplayBidonLogs,
    ParamDevice,
    TableCellBool,
    TableCellString,
    TableCellEquipment,
    TimeElapsed,
    Pumps,
  }
}
</script>
<style>
.wide-popover {
  min-width: 400px !important;
}

.devicestable td {
  padding: 3px !important;
}

.devicestable>thead>tr>th {
  background-color: #343a40;
  color: #fff;
}

.sticky>thead>tr>th {
  /*background-color: #ccc; */
  background-color: #343a40;
  opacity: 1;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
  border-top: 0;
} 


.devicestable>thead>tr>th {
  height: 130px;
  white-space: nowrap;
}
.devicestable>thead>tr>th>div {
  transform:
    translate(-9px, 0px)
    rotate(-75deg);
  width: 40px;
}

.devicestable>thead>tr>th>div>span {
  padding: 5px 10px;
}

.input-group-append {
  z-index: 1;
}

.container-lg {
  max-width: 1900px !important;
}

.blink-enter-active,
.blink-leave-active {
  animation: bounce-in .5s;
}

.blink-enter,
.blink-leave-to {
  animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media (min-width: 992px) {
  .modal .modal-huge {
    max-width: 90% !important;
    width: 90% !important;;
  }
}
</style>
