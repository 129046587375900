import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { TablePlugin } from 'bootstrap-vue'
import { VBScrollspyPlugin } from 'bootstrap-vue'
import { BootstrapVueIcons } from 'bootstrap-vue'
import UniqueId from 'vue-unique-id';

/**
 * This is the main Light Bootstrap Dashboard Vue plugin where dashboard related plugins are registerd.
 */
export default {
    install (Vue) {
        Vue.use(BootstrapVue)
        Vue.use(IconsPlugin)
        Vue.use(TablePlugin)
        Vue.use(VBScrollspyPlugin)
        Vue.use(BootstrapVueIcons)
        Vue.use(UniqueId)
    }
}
