<template>
  <b-card no-body header="Pramétrage des capteurs">
    <h1 v-if="!sensors">Chargement...</h1>
    <b-alert :show="error !== ''">{{error}}</b-alert>

    <b-row v-for="sensor in sensors" :key="sensor.system_name">
      <b-col class="col-8">
        <b-input-group class="mt-1">
          <b-input-group-prepend class="col-3">
            <span class="input-group-text col-12" :class="getclass(sensor)">{{sensor.title}}</span>
          </b-input-group-prepend>
          <b-form-input type="number" v-model="sensor.value" @change="onchanged(sensor)"></b-form-input>
          <b-input-group-append>
            <b-button @click="setdefault(sensor)">
              <b-icon-box-arrow-left></b-icon-box-arrow-left>
              défaut: {{sensor.default}}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { API_LOCATION } from '../config'

export default {
  name: 'param-sensor',
  props: {
    device_id: Number
  },
  data () {
    return {
      error: '',
      sensors: []
    }
  },
  mounted() {
    this.error = ''
    if (! this.device_id) {
      this.sensors = []
      return
    }

    this.$http.get(API_LOCATION + '/paramsensors/' + this.device_id)
      .then((response) => {
        this.sensors = response.body.param_sensors
      }, (response) => {
        console.error("get paramsensors failed", response)
        this.error = response.body
      }
    )
  },
  methods: {
    setdefault(sensor) {
      sensor.value = sensor.default
      this.onchanged(sensor)
    },
    onchanged(sensor) {
      let postdata = {
        device_id: this.device_id,
        system_name: sensor.system_name,
        value: sensor.value
      }
      this.$http.post(API_LOCATION + '/paramsensors/' + this.device_id, postdata)
        .then(() => {
          this.$emit("mustreload")
        }, (response) => {
          this.error = response.body
        }
      )
    },
    isdefault(sensor) {
      return sensor.value == sensor.default
    },
    getclass(sensor) {
      if (this.isdefault(sensor)) {
        return 'text-default'
      } else {
        return 'text-info'
      }
    }
  },
  components: {
  },
}
</script>
<style>
</style>