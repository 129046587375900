import Auth from '../Auth'
import Content from 'src/layout/Content.vue'
import NotFound from '../pages/NotFoundPage.vue'

// Admin pages
import TableList from 'src/pages/TableList.vue'
import Login from '../pages/Login.vue'

function requireAuth (to, from, next) {
  if (!Auth.loggedIn(to)) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
}

const routes = [
  {
    path: '/',
    component: Content,
    redirect: '/admin/dashboard'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/logout',
    beforeEnter (to, from, next) {
      Auth.logout()
      next('/login')
    }
  },
  {
    path: '/admin',
    component: Content,
    beforeEnter: requireAuth,
    redirect: '/admin/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: TableList
      }
    ]
  },
  { path: '*', component: NotFound }
]

export default routes
