<template>
  <b-table
    ref="plages"
    stripped
    small
    borderless
    striped
    :fields="fields"
    :items="vplages"
  >
    <template v-slot:cell(days)="data">
      <div v-for="day in getdays(data.value)" :key="day">{{day}}</div>
    </template>
  </b-table>
</template>
<script>
const DAYS_MAP = {
  "1": "Lundi",
  "2": "Mardi",
  "3": "Mercredi",
  "4": "Jeudi",
  "5": "Vendredi",
  "6": "Samedi",
  "7": "Dimanche",
}

export default {
  name: 'display-plages',
  props: {
    plages: Array[Object],
  },
  data() {
    return {
      vplages: this.plages,
      fields: [
        {
          key: 'days',
          label: 'Jours'
        }, {
          key: 'start',
          label: 'Début',
        }, {
          key: 'end',
          label: 'Fin',
        }
      ],
    }
  },
  methods:  {
    getdays: function(plage) {
      let days = []
      for (let [dayindex, day] of Object.entries(DAYS_MAP)) {
        if (plage.indexOf(dayindex) != -1) {
          days.push(day)
        }
      }
      return days
    }
  }
}
</script>