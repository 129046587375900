<template>
  <div class="block">
    <span v-if="mobilelabel" class="d-lg-none">{{ mobilelabel }}: </span>
    <b-icon-blank
      class="rounded-circle p-1"
      variant="light"
      v-bind:class="cclass"
      aria-hidden="true"
      :id="$id('tooltip')">
    </b-icon-blank>
    <b-popover ref="popover" :target="$id('tooltip')" triggers="hover" placement="topright">
      <template v-slot:title>
        {{tooltip}}
      </template>
      {{tooltip_text}}
      <div style="display: block">
        <slot name="tooltip_text"></slot>
      </div>
    </b-popover>
    <slot></slot>
    <b-button class="d-lg-none" size="sm" pill variant="outline-secondary" @click="openPopover()">
      <b-icon-envelope-open></b-icon-envelope-open>
    </b-button>
  </div>
</template>
<script>
export default {
  name: 'table-cell-bool',
  props: {
    'tooltip': null,
    'tooltip_text': null,
    'value': null,
    'dangervalue': null,
    'dangerclass': {
      type: String,
      default: 'bg-danger'
    },
    'successclass': {
      type: String,
      default: 'bg-success'
    },
    'colorclass': {
      type: String,
      default: null
    },
    'enabled': {
      type: Boolean,
      default: true,
    },
    'mobilelabel': {
      type: String,
      default: null
    }
  },
  methods: {
    openPopover() {
      this.$root.$emit('bv::hide::popover')
      this.$refs.popover.$emit('open')
    }
  },
  computed: {
    cclass: function() {
      if (this.colorclass === null) {
        if (this.enabled === false) {
          return 'bg-secondary'
        }
        if (this.dangervalue == this.value) {
          return this.dangerclass
        } else {
          return this.successclass
        }
      } else {
        return this.colorclass
      }
    }
  },
}
</script>
<style>
.block {
  white-space: nowrap;
}
.block > svg {
  display: inline-block;
}
</style>