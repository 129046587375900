<template>
  <div>
    <div :style="getStyle()" :id="$id('tooltip')" class="value">
      <slot></slot>
    </div>
    <b-popover :target="$id('tooltip')" triggers="hover" placement="top">
      <template v-slot:title>{{tooltip}}</template>
      <div style="display: block">
        <slot name="tooltip_text"></slot>
      </div>
      <ul v-if="rowalarm && rowalarm.triggered_reasons">
        <li v-for="tr in rowalarm.triggered_reasons" :key="tr">{{tr}}</li>
      </ul>
    </b-popover>
  </div>
</template>
<script>
export default {
  name: 'table-cell-string',
  props: {
    'tooltip': null,
    'rowalarm': {
      type: Object,
      default: null
    }
  },
  methods: {
    getStyle() {
      if (! this.rowalarm || ! this.rowalarm.param_alarm || ! this.rowalarm.triggered) {
        return;
      }
      let pa = this.rowalarm.param_alarm
      let props = {}
      if (pa.color) {
        props.color = pa.color
      }
      if (pa.bgcolor && ! pa.colorrow) {
        props["background-color"] = pa.bgcolor
      }
      return props
    }
  },
}
</script>
<style>
.value {
  padding: 1px;
  padding-left: 5px;
  border-radius: 3px;
}
</style>