<template>
    <div class="pumps">
        <table-cell-bool
            v-for="pump of vpumps"
            :key="pump.id"
            :tooltip="getname(pump)"
            :colorclass="getmap(pump).class"
            :mobilelabel="pump.name"
        >
            <template v-slot:tooltip_text>
              Mode: {{pump.mode}}<br/>
              Programmation: {{pump.programmation}}
              <div v-if="pump.name === 'Filtration'">
                Pompe à vitesse variable: {{device.variable_pressure ? 'Oui' : 'Non'}}
              </div>
              <display-plages v-if="pump.plages" :plages="pump.plages"></display-plages>
            </template>
            <i class="overicon">{{ getmap(pump).letter }}</i>
        </table-cell-bool>
    </div>
</template>
  
<script>
import TableCellBool from './TableCellBool'
import DisplayPlages from './DisplayPlages'

const pump_letter_map = new Map()
pump_letter_map.set('ON', {letter: 'M', class: 'bg-orange'})
pump_letter_map.set('OFF', {letter: 'O', class: 'bg-danger'})
pump_letter_map.set('AUTO', {letter: 'A', class: 'bg-success'})
pump_letter_map.set(null, {letter: '', class: 'bg-info'})

const pump_name_map = new Map()
// pump_name_map.set("Pompe chlore", "Pompe désinfectant")
// pump_name_map.set("Pompe pH-", "Pompe pH")

export default {
  name: 'pumps',
  props: {
    'device': Object
  },
  data() {
    let pumps = []
    for (let e of this.device.equipments) {
      e.name = e.name.trim()
      let lowername = e.name.toLowerCase()
      if (["filtration", "pompe ph-", "pompe chlore"].indexOf(lowername) != -1) {
        pumps.push(e)
      }
    } // Fixed order
    for (let e of this.device.equipments) {
      if (e.name.toLowerCase().startsWith("pompe") && pumps.indexOf(e) == -1) {
        pumps.push(e)
      }
    }
    return {
      vpumps: pumps
    }
  },
  methods: {
    getmap: function(pump) {
      let xmap = pump_letter_map.get(pump.mode)
      if (!xmap) {
        xmap = pump_letter_map.get(null)
      }
      // Uggy map copy
      let map = {...xmap}
      if (pump.name == "Filtration" && this.device.variable_pressure && pump.mode == "ON") {
        map.class = "bg-success"
      }
      return map
    },
    getname: function(pump) {
      let name = pump_name_map.get(pump.name)
      if (name) {
        return name
      } else {
        return pump.name
      }
    }
  },
  components: {
    TableCellBool,
    DisplayPlages
  }
}
</script>
<style lang="scss">
.pumps div {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.overicon {
  position: relative;
  left: -13px;
  font-weight: bold;
  font-size: 0.8em;
  user-select: none;
  color: black;
  pointer-events: none;
}

.bg-orange {
  background-color: var(--orange);
}
</style>
