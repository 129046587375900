<template>
    <label>
        <slot></slot> <div class="ml-2">{{elapsed}}</div>
    </label>
</template>
<script>
import * as moment from 'moment'
export default {
  name: 'time-elapsed',
  data() {
    return {
      interval: null,
      elapsed: ''
    }
  },
  props: {
    date: {
      required: true
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.update()
    }, 4000);
    this.update()
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods:{
    update() {
      this.elapsed = moment(this.date).fromNow()
    }
  }
}
</script>
<style>
label div {
  display: inline-block;
}
</style>