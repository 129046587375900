import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import VueResource from 'vue-resource'
import Bootstrap from './bootstrap'

// router setup
import routes from './routes/routes'
import * as moment from 'moment'

// Sentry
import * as Sentry from "@sentry/vue";

Vue.config.productionTip = false

moment.locale('fr')

// plugin setup
Vue.use(VueRouter)
Vue.use(Bootstrap)
Vue.use(VueResource)

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'nav-item active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
})

Sentry.init({
  Vue,
  dsn: 'https://1eef7c683c174a2eb7fd57006d2a46f4@sentry.io/2439633',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router
})
