<template>
  <b-card
    header="Identification"
    class="logincard"
  >
    <b-card-text>
      <b-form class="form-sigin" @submit.prevent="login">
        <b-form-group
          id="input-email"
          label="Identifiant:"
          label-for="input-email"
        >
          <b-form-input
            id="input-email"
            v-model="email"
            required
            placeholder="Identifiant"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-email"
          label="Mot de passe:"
          label-for="input-email"
        >
          <b-form-input
            id="input-password"
            v-model="pass"
            type="password"
            required
            placeholder="Mot de passe"
          ></b-form-input>
        </b-form-group>
        <b-form-checkbox v-model="rememberme" disabled>Rester connecté</b-form-checkbox>
        <b-button type="submit" variant="primary">Se connecter</b-button>
        <b-alert
          :show="error"
          variant="danger"
        >Identifiants incorrects: {{texterror}}</b-alert>
      </b-form>
    </b-card-text>
  </b-card>
</template>

<script>
import Auth from '../Auth'

export default {
  data () {
    return {
      email: '',
      pass: '',
      rememberme: true,
      error: 0,
      texterror: '',
    }
  },
  methods: {
    login () {
      Auth.login(this.email, this.pass).then(() => {
        this.error = 0
        this.texterror = ''
        if (this.$route.query.redirect != null) {
          this.$router.push(this.$route.query.redirect)
        } else {
          this.$router.push("/admin/dashboard")
        }
      }, error => {
        this.texterror = error
        this.$router.replace(this.$route.query.redirect || '/')
        this.error = 30
      })
    }
  }
}
</script>

<style>
.logincard {
  width: 100%;
  max-width: 330px;
  margin: auto;
  margin-top: 40px;
}
</style>
