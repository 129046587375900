<template>
  <b-card no-body>
    <b-tabs card>
      <!-- Paramètres capteur -->
      <b-tab title="Capteurs">
        <b-card-text>
          <param-sensor :device_id="device.id" @mustreload="onMustReload"></param-sensor>
        </b-card-text>
      </b-tab>

      <b-tab title="Alarmes">
        <b-card-text>
          <!-- Alarm pression -->
          <param-alarm-list
            v-if="device.pressure.device_sensor_id"
            unit="Bar"
            header="Pression"
            :device_sensor_id="device.pressure.device_sensor_id"
            :lines="device.pressure.param_alarm"
            @mustreload="onMustReload">
          </param-alarm-list>

          <!-- Pression de service single value -->
          <param-alarm-list
            v-if="device.pressure.device_sensor_id"
            header="Pression de service"
            unit="Bar"
            :device_sensor_id="device.pressure.device_sensor_id"
            :step="0.01"
            :formatter="roundDecimals(2)"
            :lines="device.pressure.param_alarm"
            @mustreload="onMustReload"
            variant="single">
          </param-alarm-list>

          <!-- Alarm PH -->
          <param-alarm-list
            v-if="device.ph.device_sensor_id"
            header="PH"
            unit="pt"
            :device_sensor_id="device.ph.device_sensor_id"
            :lines="device.ph.param_alarm"
            @mustreload="onMustReload">
          </param-alarm-list>

          <!-- Alarm Redox -->
          <param-alarm-list
            header="Redox"
            v-if="device.redox.device_sensor_id"
            unit="mV"
            :device_sensor_id="device.redox.device_sensor_id"
            :lines="device.redox.param_alarm"
            @mustreload="onMustReload">
          </param-alarm-list>

          <!-- Alarm Température -->
          <param-alarm-list
            header="Temp"
            v-if="device.temperature.device_sensor_id"
            unit="°C"
            :device_sensor_id="device.temperature.device_sensor_id"
            :lines="device.temperature.param_alarm"
            @mustreload="onMustReload">
          </param-alarm-list>

          <!-- Alarm Sel -->
          <param-alarm-list
            header="Sel"
            v-if="device.sel.device_sensor_id"
            unit="mV"
            :device_sensor_id="device.sel.device_sensor_id"
            :lines="device.sel.param_alarm"
            @mustreload="onMustReload">
          </param-alarm-list>
        </b-card-text>
      </b-tab>
      <b-tab title="Equipements">
        <b-card-text>
          <equipment-list :equipments="device.equipments"></equipment-list>
        </b-card-text>
      </b-tab>

      <b-tab title="Permissions">
        <b-card-text class="permissions">
          Utilisateurs ayant accès au bassin:
          <multiselect
            v-model="allowed_users"
            :options="users"
            :multiple="true"
            track-by="user_id"
            label="login"
            @select="userAdded"
            @remove="userRemoved"
            placeholder="Sélectionner un utilisateur"
            selectLabel="Appuyer sur entrer pour ajouter"
            deselectLabel="Appuyer sur entrer pour supprimer"
          ></multiselect>
        </b-card-text>
      </b-tab>

      <b-tab title="Autre paramètres">
        <b-card-text>
          Renommer:
          <b-form-input v-model="name" placeholder="Nom du bassin"
            @change="onNameChanged">
          </b-form-input>
          <hr>
          <b-form-checkbox v-model="production"
            @change="onProductionChanged">En production
          </b-form-checkbox>
          <hr>
          <b-form-checkbox v-model="solista" switch size="lg"
            @change="onSolistaChanged">Solista
          </b-form-checkbox>
          <hr>
          <b-form-checkbox v-model="variable_pressure" switch size="lg"
            @change="onVariablePressureChanged">Pompe à vitesse variable
          </b-form-checkbox>

          <b-button size="sm" @click="onDelete()" class="align-top btn-danger mr-2 float-right">
            <b-icon-trash></b-icon-trash>
            Supprimer le bassin
          </b-button>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script>
import { API_LOCATION } from '../config.js'

import ParamAlarmList from '../components/ParamAlarmList'
import ParamSensor from '../components/ParamSensor'
import EquipmentList from '../components/EquipmentList'
import Multiselect from 'vue-multiselect'

export default {
  name: 'param-device',
  props: {
    device: Object
  },
  data() {
    return {
      name: this.device.name,
      solista: this.device.solista,
      variable_pressure: this.device.variable_pressure,
      production: this.device.production,
      users: [],
      allowed_users: [],
    }
  },
  methods: {
    onMustReload() {
      this.$emit("mustreload")
    },
    roundDecimals(decimals) {
      return function(str) {
        return Number(str).toFixed(decimals)
      }
    },
    onNameChanged(name) {
      this.$http.post(API_LOCATION + '/device/' + this.device.id + '/rename', {
        name: name
      }).then(() => {
        this.onMustReload()
      })
    },
    onSolistaChanged(solista_enabled) {
      this.$http.post(API_LOCATION + '/device/' + this.device.id + '/toggle_solista', {
        solista_enabled: solista_enabled
      }).then(() => {
        this.onMustReload()
      })
    },
    onVariablePressureChanged(variable_pressure) {
      this.$http.post(API_LOCATION + '/device/' + this.device.id + '/toggle_variable_pressure', {
        variable_pressure: variable_pressure
      }).then(() => {
        this.onMustReload()
      })
    },
    onProductionChanged(production) {
      this.$http.post(API_LOCATION + '/device/' + this.device.id + '/toggle_production', {
        production: production
      }).then(() => {
        this.onMustReload()
      })
    },
    onDelete() {
      this.$bvModal.msgBoxConfirm(
        'Supprimer ' + this.device.name + ' (' + this.device.id + ') ?', {
          okVariant: 'danger',
          okTitle: 'Supprimer',
          cancelTitle: 'Annuler',
          centered: true
        }
      ).then(value => {
        if (value) {
          this.$http.delete(API_LOCATION + '/device/' + this.device.id)
          .then(() => {
            this.onMustReload()
          }, () => {
            this.onMustReload()
          })
        }
      })
    },
    async userAdded(user) {
      await this.$http.put(API_LOCATION + '/permissions/' + this.device.id, {
        user_id: user.user_id
      })
    },
    async userRemoved(user) {
      await this.$http.post(API_LOCATION + '/permissions/' + this.device.id + '/' + user.user_id, {})
    },
    async loadUsers() {
      let response = await this.$http.get(API_LOCATION + '/permissions')
      this.$data.users = response.body
      let allowed_users = []
      if (this.$data.users) {
        for (let user of this.$data.users) {
          if (
            (user.device_ids.indexOf(this.device.id) != -1)
          ) {
            allowed_users.push(user)
          }
        }
      }
      this.$data.allowed_users = allowed_users
    }
  },
  mounted() {
    this.loadUsers()
  },
  components: {
    ParamAlarmList,
    ParamSensor,
    EquipmentList,
    Multiselect,
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.permissions {
  min-height:  400px;
}
</style>