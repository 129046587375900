<template>
  <div>
    <h1 v-if="!logs">Chargement...</h1>
    <b-alert :show="error !== ''">{{error}}</b-alert>
    <b-table
      ref="logstable"
      stripped
      show-empty
      small
      primary-key="id"
      selectable
      borderless
      striped
      select-mode="multi"
      selected-variant="danger"
      :fields="fields"
      :items="logs"
      @row-selected="rowselected"
    >
      <!-- Example scoped slot for select state illustrative purposes -->
      <template v-slot:cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template v-slot:cell(names)="data">
        <b-row v-for="line in data.value" :key="String(line)">
          <b-col>{{line}}</b-col>
        </b-row>
      </template>
      <template v-slot:cell(previous_values)="data">
        <b-row v-for="line in data.value" :key="String(line)">
          <b-col>{{line}}</b-col>
        </b-row>
      </template>
      <template v-slot:cell(new_values)="data">
        <b-row v-for="line in data.value" :key="String(line)">
          <b-col>{{line}}</b-col>
        </b-row>
      </template>
      <template v-slot:cell(seen)="data">
        <b-icon :variant="data.value ? 'success' : 'warning'" :icon="data.value ? 'check' : 'exclamation-triangle'"></b-icon>
      </template>
    </b-table>
  </div>
</template>
<script>
import { API_LOCATION } from '../config'
import { diff } from 'deep-object-diff';

export default {
  name: 'display-logs',
  props: {
    device_id: Number,
    show_seen: Boolean,
  },
  data() {
    return {
      error: '',
      logs: [],
      fields: [
        {
          key: 'selected',
          label: ''
        }, {
          key: 'date',
          label: 'Date'
        }, {
          key: 'seen',
          label: 'Ack.'
        }, {
          key: 'equipment',
          label: 'Équippement'
        }, {
          key: 'names',
          label: 'Nom'
        }, {
          key: 'previous_values',
          label: 'Ancienne valeur'
        }, {
          key: 'new_values',
          label: 'Nouvelle valeur'
        }
      ],
    }
  },
  methods: {
    selectAll() {
      this.$refs.logstable.selectAllRows()
    },
    unselectAll() {
      this.$refs.logstable.clearSelected()
    },
    rowselected(rows) {
      let log_ids = []
      for(let row of rows) {
        if (log_ids.indexOf(row.log_id) == -1) {
          log_ids.push(row.log_id)
        }
      }
      this.$emit("selectionchanged", log_ids)
    },
    removeLogIds(log_ids) {
      this.logs = this.logs.filter(log => {
        return log_ids.indexOf(log.log_id) === -1
      })
    }
  },
  mounted() {
    this.error = '';
    if (! this.device_id) {
      this.logs = []
      return
    }
    this.$http.get(API_LOCATION + '/logs/' + this.device_id, {params: {show_seen: this.show_seen}})
      .then((response) => {
        let logs = []
        for (let idx in response.body) {
          let logline = response.body[idx]
          let logdiff = diff(logline.previous_value, logline.new_value)
          let previous_values = []
          let new_values = []
          let names = []

          for (let key in logdiff) {
            previous_values.push(logline.previous_value[key])
            new_values.push(logdiff[key])
            names.push(key)
          }
          logs.push({
            'id': logline.log_id,
            'log_id': logline.log_id,
            'date': logline.date,
            'equipment': logline.name,
            'previous_values': previous_values,
            'new_values': new_values,
            'names': names,
            'seen': logline.seen
          })
        }
        this.logs = logs;
      }, (response) => {
        this.logs = []
        this.error = response.body;
      }
    )
  },
}
</script>