<template>
  <div :id="$id('tooltip')">
    <b-icon-blank
      class="rounded-circle p-1"
      variant="light"
      v-bind:class="cclass"
      v-if="enabled">
    </b-icon-blank>
    <div v-else>
      {{disabled_text}}
    </div>
    <b-popover :target="$id('tooltip')" triggers="hover" placement="top">
      <template v-slot:title>
        {{tooltip}}
      </template>
      <div style="display: block">
        <slot name="tooltip_text"></slot>
      </div>
    </b-popover>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'table-cell-equipment',
  props: {
    tooltip: null,
    value: null,
    truevalue: null,
    disabled_text: {
      type: String,
      default: '-'
    },
    enabled: {
      type: Boolean,
      default: true
    },
    trueclass: {
      type: String,
      default: 'bg-danger'
    },
    falseclass: {
      type: String,
      default: 'bg-success'
    },
  },
  computed: {
    cclass: function() {
      if (this.truevalue == this.value) {
        return this.trueclass;
      } else {
        return this.falseclass;
      }
    }
  },
}
</script>
<style>
.bg-yellow {
  background-color: #cdc700 !important;
}
</style>