import { Http } from 'vue-resource'
import { EventBus } from './eventbus.js';

export default {
  login (email, pass) {
    return new Promise((resolve, reject) => {
      loginRequest(email, pass)
      .then(
        response => {
          localStorage.token = response.body.token
          Http.headers.common['token'] = localStorage.token
          resolve(response.body)
      },
        error => {
          delete Http.headers.common['token']
          delete localStorage.token
          reject(error.bodyText)
        }
      )
    })
  },

  getToken () {
    return localStorage.token
  },

  logout () {
    delete Http.headers.common['token']
    delete localStorage.token
    EventBus.$emit("loggedin", false)
  },

  loggedIn (to) {
    let token
    if (to && to.query.token) {
      token = to.query.token
    } else {
      token = this.getToken()
    }
    if (token) {
      Http.headers.common['token'] = token
    }
    EventBus.$emit("loggedin", !!token)
    return !!token
  },
}

function loginRequest (email, pass) {
  return Http.post("/login", {login: email, password: pass})
}
