<template>
  <div>
    <b-table
      ref="alarmstriggered"
      stripped
      small
      primary-key="id"
      borderless
      striped
      :fields="fields"
      :items="vreasons"
    >
      <template v-slot:cell(reason)="data">
        <div v-if="data.item.type == 'alarm'">
            {{data.item.sensor}}
            <span v-for="r in data.item.alarm.triggered_reasons" :key="r">{{r}}</span>
        </div>
        <div v-else-if="data.item.type == 'pump'">
            Pompe de filtration: mode = {{data.item.mode}}
        </div>
        <div v-if="data.item.type == 'logs'">
            Présence de modifications non acquittées
        </div>
        <div v-if="data.item.type == 'bidon'">
            Bidon ({{data.item.bidon.name}}) le {{data.item.bidon.last_update | dateFormat('DD/MM/YYYY HH:mm')}}: Fin de bidon
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import * as moment from 'moment'
export default {
  name: 'alarms-triggered',
  props: {
    reasons: Array
  },
  data() {
    return {
      vreasons: this.reasons,
      fields: [
        {
          key: 'reason',
          label: 'Alarm reasons'
        },
      ],
    }
  },
  filters: {
    dateFormat(value, format) {
      return moment(value).format(format)
    },
  },
}
</script>