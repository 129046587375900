<template>
  <b-card>
    <template v-slot:header>
      <div class="clearfix">
        {{header}}
        <b-button class="float-right" size="sm"
          v-if="canAdd()" variant="outline-primary"
          @click="onAlarmAdd">
          <b-icon-plus></b-icon-plus>
        </b-button>
      </div>
    </template>

    <b-row v-for="(line, index) in vlines" :key="line.id">
      <b-col>
        <param-alarm-line
          v-bind="line"
          :unit="unit"
          :variant="variant"
          :step="step"
          :formatter="formatter"
          @updated="onUpdated"
          @remove="onAlarmRemove(index, $event)"
          @save="onAlarmSave(index, $event)">
        </param-alarm-line>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import ParamAlarmLine from './ParamAlarmLine';
import { API_LOCATION } from '../config';
export default {
  name: 'param-alarm-list',
  props: {
    header: {
      type: String,
    },
    unit: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: null,
    },
    device_sensor_id: {
      type: Number,
      required: true,
    },
    formatter: null,
    step: Number,
    lines: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      vlines: this.lines.filter((value) => {
        return value.variant === this.variant
      }),
    }
  },
  methods: {
    canAdd() {
      return this.vlines.filter((value) => {
        return value.param_alarm_id === null
      }).length == 0;
    },
    onUpdated() {
      this.$emit("mustreload");
    },
    onAlarmRemove(index, paramalarm) {
      if (! paramalarm.param_alarm_id) {
        this.$delete(this.vlines, index);
        this.$emit("mustreload");
      } else {
        this.$http.delete(API_LOCATION + "/paramalarm/" + paramalarm.param_alarm_id)
          .then(() => {
            this.$delete(this.vlines, index);
            this.$emit("mustreload");
        });
      }
    },
    onAlarmSave(index, paramalarm) {
      this.$http.post(API_LOCATION + "/paramalarm", paramalarm).then(response => {
        if (response.body.param_alarm_id !== 'undefined') {
          this.vlines[index].param_alarm_id = response.body.param_alarm_id;
          this.$emit("mustreload");
        }
      });
    },
    onAlarmAdd() {
      this.vlines.push({
        param_alarm_id: null,
        device_sensor_id: this.device_sensor_id,
        period_start: null,
        period_end: null,
        minimum: 0,
        maximum: 0,
        color: null,
        bgcolor: null,
      });
    }
  },
  components: {
    ParamAlarmLine
  }
}
</script>
