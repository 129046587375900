<template>
  <footer class="footer d-none d-lg-block">
    <div class="container">
      <span class="text-muted">(c) 2022 Laurent Coustet (Version: {{version}})</span>
    </div>
  </footer>
</template>
<script>
import {version} from '../../package.json';
export default {
  name: 'content-footer',
  data() {
    return {
      'version': version
    }
  }
}
</script>
<style>
.footer {
  margin-top: 10px;
  bottom: 0;
  width: 100%;
  height: 20px;
  line-height: 20px;
  text-align: right;
}
</style>