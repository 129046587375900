<template>
  <b-form inline>
    <date-range-picker
      auto-apply
      :ranges="ranges"
      :locale-data="locale"
      :showDropdowns="true"
      :min-date="minDate"
      :max-date="maxDate"
      :linked-calendars="false"
      opens="right"
      v-model="vrange"
      date-util="moment"
      @update="updated"
    >
    </date-range-picker>
    <b-button variant="secondary" @click="removeRange">
      <b-icon-x></b-icon-x>
    </b-button>
    <label class="mx-2" v-if="variant !== 'single'">Min:</label>
    <label class="mx-2" v-else>Valeur:</label>
    <b-input
      class="mx-2 maxlength"
      placeholder=""
      type="number"
      :step="step"
      :formatter="formatter"
      lazy-formatter
      debounce="500"
      @input="updated"
      v-model="vminimum"
    ></b-input>
    <label class="mx-2" v-if="variant !== 'single'">Max:</label>
    <b-input
      v-if="variant !== 'single'"
      class="mx-2 maxlength"
      placeholder=""
      type="number"
      :step="step"
      :formatter="formatter"
      lazy-formatter
      debounce="500"
      v-model="vmaximum"
    ></b-input>
    <label class="mr-2">{{unit}}</label>
    <label class="mx-2"
      v-if="variant !== 'single'"
      v-bind:style="{'color': vcolor, 'background-color': vbgcolor}"
    >
      Couleur:
    </label>
    <v-swatches
      v-if="variant !== 'single'"
      v-model="vcolor"
      :swatches="colors_light"
      shapes="circles"
      row-length="12"
      show-fallback
      popover-to="left"
      @input="updated"
      v-b-tooltip.hover.left title="Couleur de texte"></v-swatches>
    <v-swatches
      v-if="variant !== 'single'"
      v-model="vbgcolor"
      class="mr-2"
      :swatches="colors_mat_light"
      shapes="circles"
      popover-to="left"
      row-length="12"
      show-fallback
      @input="updated"
      v-b-tooltip.hover.right title="Couleur de fond"></v-swatches>
    <b-form-checkbox
      v-if="variant !== 'single'"
      class="mr-2"
      v-model="vcolorrow"
      switch
      @input="updated"
    >
      Ligne complète
    </b-form-checkbox>
    <b-button variant="danger" @click="$emit('remove', serialize())">
      <b-icon-trash></b-icon-trash>
    </b-button>
    <b-button v-if="param_alarm_id === null" variant="success" @click="$emit('save', serialize())">
      <b-icon-cloud-upload></b-icon-cloud-upload>
    </b-button>
  </b-form>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker'
import VSwatches from 'vue-swatches'
import { API_LOCATION } from '../config'

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import "vue-swatches/dist/vue-swatches.css"

import * as moment from 'moment';


const colors_light = [
  '#EF9A9A',
  '#F48FB1',
  '#CE93D8',
  '#B39DDB',
  '#9FA8DA',
  '#90CAF9',
  '#81D4FA',
  '#80DEEA',
  '#80CBC4',
  '#A5D6A7',
  '#C5E1A5',
  '#E6EE9C',
  '#FFF59D',
  '#FFE082',
  '#FFCC80',
  '#FFAB91',
  '#BCAAA4',
  '#EEEEEE',
  '#B0BEC5',
  '#000000',
]

const colors_mat_light = [
  '#EF9A9A',
  '#F48FB1',
  '#CE93D8',
  '#B39DDB',
  '#9FA8DA',
  '#90CAF9',
  '#81D4FA',
  '#80DEEA',
  '#80CBC4',
  '#A5D6A7',
  '#C5E1A5',
  '#E6EE9C',
  '#FFF59D',
  '#FFE082',
  '#FFCC80',
  '#FFAB91',
  '#BCAAA4',
  '#EEEEEE',
  '#B0BEC5'
]


export default {
  name: 'param-alarm-line',
  props: {
    param_alarm_id: Number,
    device_sensor_id: {
      type: Number,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: null,
    },
    minimum: Number,
    maximum: Number,
    period_start: String,
    period_end: String,
    color: String,
    bgcolor: String,
    colorrow: {
      type: Boolean,
      default: false,
    },
    formatter: null,
    step: {
      type: Number,
      default: 0.1,
    }
  },
  data () {
    let period_start = moment(this.period_start, 'DD/MM');
    let period_end = moment(this.period_end, 'DD/MM');
    return {
      ranges: false,
      timer: null,
      minDate: moment().startOf('year').format(),
      maxDate: moment().endOf('year').format(),
      vrange: {
        startDate: period_start.isValid() ? period_start : null,
        endDate: period_end.isValid() ? period_end : null,
      },
      vcolorrow: this.colorrow,
      vminimum: this.minimum,
      vmaximum: this.maximum,
      vcolor: this.color,
      vbgcolor: this.bgcolor,
      colors_light: colors_light,
      colors_mat_light: colors_mat_light,
      locale: {
        direction: 'ltr',
        format: 'dd/mm',
        separator: ' - ',
        applyLabel: 'Appliquer',
        cancelLabel: 'Annuler',
        weekLabel: 'Semaine',
        customRangeLabel: 'Custom Range',
        firstDay: 1,
      }
    }
  },
  methods: {
    removeRange() {
      this.vrange.startDate = null
      this.vrange.endDate = null
      this.updated()
    },
    serialize() {
      let period_start = moment(this.$data.vrange.startDate);
      let period_end = moment(this.$data.vrange.endDate);
      return {
        param_alarm_id: this.param_alarm_id,
        device_sensor_id: this.device_sensor_id,
        minimum: this.$data.vminimum,
        maximum: this.$data.vmaximum,
        period_start: period_start.isValid() ? period_start.format('DD/MM') : null,
        period_end: period_end.isValid() ? period_end.format('DD/MM') : null,
        color: this.$data.vcolor,
        bgcolor: this.$data.vbgcolor,
        colorrow: this.$data.vcolorrow,
        variant: this.variant,
      };
    },
    updated() {
      if (! this.param_alarm_id) {
        return;
      }
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.$http.post(API_LOCATION + "/paramalarm", this.serialize())
          .then(() => {
            this.$emit('updated', this.serialize());
            this.timer = null;
        });
      }, 1000);
    }
  },
  components: {
    DateRangePicker,
    VSwatches,
  },
}
</script>
<style>
.reportrange-text {
  min-width: 150px;
}
.maxlength {
  width: 100px !important;
}
.yearselect {
  visibility: hidden;
}
</style>