<template>
  <div>
    <b-navbar toggleable="lg" type="dark" class="navbar-dark bg-dark" v-if="!screenshotMode">
      <b-navbar-brand href="#">Domo piscine</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form right>
            <b-form-checkbox
              switch
              v-model="dark"
              value="dark"
              unchecked-value="light"
              class="mr-2"
              @input="changeDarkMode">
            Dark mode
          </b-form-checkbox>
          </b-nav-form>
          <b-nav-form>
            <b-form-input
              v-model="filter"
              @input="onSearch"
              type="search"
              id="filterInput"
              placeholder="Rechercher"
            ></b-form-input>
          </b-nav-form>
          <b-nav-form right v-if="loggedIn">
            <b-button size="sm" variant="primary"  @click="$router.push('/logout')">
              Déconnecter
            </b-button>
          </b-nav-form >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import Auth from '../Auth'
import { EventBus } from '../eventbus.js';


export default {
  data() {
    return { 
      dark: "light",
      filter: '',
      loggedIn: Auth.loggedIn(),
    }
  },
  created () {
    Auth.onChange = loggedIn => {
      this.loggedIn = loggedIn
    },
    EventBus.$on("loggedin", loggedin => {
      this.loggedIn = loggedin
    })
  },
  mounted() {
    if (localStorage.darkMode !== 'undefined') {
      this.dark = localStorage.darkMode
    } else {
      // device preferrence
      this.dark = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark ' : 'light'
    }
  },
  computed: {
    screenshotMode: function() {
      return (localStorage && localStorage.screenshotmode === 'true')
    }
  },
  methods: {
    changeDarkMode(mode) {
      localStorage.darkMode = mode
      document.body.setAttribute('data-theme', mode);
    },
    onSearch(filter) {
      this.$root.$emit('global_filter', filter);
    }
  }
}
</script>
<style>
</style>
