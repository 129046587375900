<template>
  <div>
    <h1 v-if="!logs">Chargement...</h1>
    <b-alert :show="error !== ''">{{error}}</b-alert>
    <b-table
      ref="bidonlogstable"
      stripped
      small
      primary-key="id"
      borderless
      striped
      :fields="fields"
      :items="logs"
    >
      <template v-slot:cell(value)="data">
        <b-icon-blank
          class="rounded-circle p-1"
          variant="light"
          v-bind:class="{'bg-success': data.value === 'Présent', 'bg-danger': data.value !== 'Présent'}">
        </b-icon-blank>
        {{data.value}}
      </template>
    </b-table>
  </div>
</template>
<script>
import { API_LOCATION } from '../config'

export default {
  name: 'display-bidon-logs',
  props: {
    device_id: Number,
  },
  data() {
    return {
      error: '',
      logs: [],
      fields: [
        {
          key: 'date',
          label: 'Date'
        }, {
          key: 'name',
          label: 'Nom'
        }, {
          key: 'value',
          label: 'Valeur',
          formatter: (value) => {
            return value == '1' ? 'Présent' : 'Absent'
          }
        },
      ],
    }
  },
  mounted() {
    this.error = '';
    if (! this.device_id) {
      this.logs = []
    } else {
      this.$http.get(API_LOCATION + '/logs_bidon/' + this.device_id)
        .then((response) => {
          this.logs = response.body
        }, () => {
          this.logs = []
          this.error = 'Aucune modification de bidon détectée'
        }
      )
    }
  },
}
</script>