<template>
  <b-card no-body header="Équipements">
    <b-tabs pills card vertical>
      <b-tab
        v-for="(e, index) in vequipments" :key="e.equipment_id + '_' + e.ignored"
        :title="e.name + ' (n°'+e.swimo_number+')'"
      >
        <b-card-text>
          <b-button v-if="e.ignored" variant="info" @click="setMute(e.equipment_id, false, index)">
            <b-icon-volume-up></b-icon-volume-up>
            Ne plus mettre en sourdine
          </b-button>
          <b-button v-else variant="info" @click="setMute(e.equipment_id, true, index)">
            <b-icon-volume-mute></b-icon-volume-mute>
            Mettre en sourdine cet équipement
          </b-button>
          <json-view :data="getDisplayData(e)" :rootKey="e.name"></json-view>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script>
import { JSONView } from "vue-json-component";
import { API_LOCATION } from '../config'

export default {
  name: 'equipment-list',
  props: {
    equipments: Array
  },
  data() {
    return {
      vequipments: this.equipments
    }
  },
  methods: {
    getDisplayData(e) {
      return {
        'Mode': e.mode,
        'Programmation': e.programmation,
        'Puissance': e.power + ' ' + e.unit_power,
        'Consigne': e.consigne + ' ' + e.unit_consigne,
        'Volume': e.volume,
        'N° swimo': e.swimo_number,
        'En sourdine': e.ignored ? 'Oui' : 'Non',
      }
    },
    setMute(equipment_id, muted, index) {
      this.$http.post(API_LOCATION + '/equipment/setignored/' + equipment_id, {"ignored": muted})
        .then(() => {
          this.$set(this.vequipments[index], 'ignored', !!muted)
        }
      )
    }
  },
  components: {
    "json-view": JSONView,
  }
}
</script>