<template>
  <div>
    Désolé cette page n'existe pas
  </div>
</template>

<script>
  export default {}

</script>
