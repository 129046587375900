<template>
  <div class="wrapper">
    <div class="main-panel">
      <top-navbar></top-navbar>
      <router-view></router-view>
      <content-footer></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from './layout/TopNavbar.vue'
import ContentFooter from './layout/ContentFooter.vue'


export default {
  components: {
    TopNavbar,
    ContentFooter,
  }
}
</script>
<style>
.navbar {
  color: #ccc;
}
</style>